<template>
  <div>
    <div class="div-form">
      <!-- <Messages /> -->
      <Alert type="success" v-if="showSuccess">
        <span> Solicitação enviada com sucesso! </span>
      </Alert>
      <SendConfirmMailMobile v-if="showSendConfirmMailMobile" :typeConfirm="type" :email="forgot.email"
        :mobile="forgot.mobile" />
      <div class="div-title">
        <span class="title title-forgot">Esqueceu a senha</span>
      </div>
      <div class="title div-subtitle">
        <span>
          Informe o E-mail ou WhatsApp associado a sua conta, enviaremos um link
          com as instruções.
        </span>
      </div>
      <RadioGroup field="type" :options="[
        { text: 'E-mail', value: 'email' },
        { text: 'WhatsApp', value: 'mobile' },
      ]" v-model="type" />
      <b-row>
        <b-col sm="12" v-if="type == 'email'">
          <InputText title="E-mail" field="email" :formName="formName" :maxLength="50" v-model="forgot.email">
            <div class="glyphicon">
              <i class="fa-solid fa-envelope"></i>
            </div>
          </InputText>
        </b-col>
        <b-col sm="12" v-else>
          <InputText title="Celular" field="mobile" :formName="formName" :mask="['(##) ####-####', '(##) #####-####']"
            :maxLength="50" v-model="forgot.mobile">
            <div class="glyphicon icon-whatsapp">
              <i class="fa-brands fa-whatsapp"></i>
            </div>
          </InputText>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <Button _key="send" type="primary" title="Enviar" :disabled="disabled" classIcon="fa-solid fa-paper-plane-top"
            size="medium" :clicked="send" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Messages from "@nixweb/nixloc-ui/src/component/shared/Messages.vue";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import SendConfirmMailMobile from "./SendConfirmMailMobile.vue";
import ForgotPassword from "@/components/modules/adm/login/ForgotPassword.js";

import { mapGetters, mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "ForgotPassword",
  components: {
    InputText,
    Button,
    Messages,
    RadioGroup,
    Alert,
    SendConfirmMailMobile,
  },
  data() {
    return {
      formName: "forgot",
      forgot: new ForgotPassword(),
      type: "email",
      urlforgot: "api/v1/adm/auth/forgot-password",
      showSuccess: false,
      showSendConfirmMailMobile: false,
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapState("generic", ["event"]),
    disabled() {
      if (this.type == "email" && this.forgot.email) return false;
      if (this.type == "mobile" && this.forgot.mobile) return false;

      return true;
    },
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", ["removeLoading"]),
    send() {
      this.showSuccess = false;
      let params = { url: this.urlforgot, obj: this.forgot };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.showSuccess = true;
        } else {
          if (response.content) this.showSendConfirmMailMobile = true;
          if (this.type == "email") {
            this.forgot.email = response.content;
          } else {
            this.forgot.mobile = response.content;
          }
        }
        this.removeLoading(["send"]);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "stopSendConfirmEmailMobile") {
          this.showSendConfirmMailMobile = false;
        }
      },
      deep: true,
    },
    type: {
      handler() {
        this.forgot.email = "";
        this.forgot.mobile = "";
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.div-form {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
}

.div-title {
  margin-bottom: 5px;
}

.div-subtitle {
  margin-bottom: 15px;
}

.title-forgot {
  font-size: 25px;
}

.title-rodape {
  font-size: 13px;
}

.icon-whatsapp {
  color: green;
}
</style>
