<template>
  <div class="main" v-if="!stopSend">
    <div class="text-center">
      <Timer :title="'Enviando confirmação para ' + email + mobile + ' em'" :initialTimer="10" :endTime="sendConfirm" />
      <div class="div-btn">
        <Button _key="cancel" type="danger" title="Cancelar" classIcon="fa-solid fa-ban" size="small" :clicked="cancel" />
      </div>
    </div>
  </div>
</template>
<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Timer from "@nixweb/nixloc-ui/src/component/shared/Timer";

import { mapActions, mapMutations } from "vuex";

export default {
  name: "SendConfirmMailMobile",
  components: {
    Button,
    Timer,
  },
  props: {
    typeConfirm: String,
    email: {
      type: String,
      default: "",
    },
    mobile: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      urlConfirm: "/api/v1/adm/auth/sendconfirm-mail-mobile",
      stopSend: false,
    };
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading"]),
    sendConfirm() {
      if (this.stopSend) return;
      let params = {
        url: this.urlConfirm,
        obj: {
          typeConfirm: this.typeConfirm,
          email: this.email,
          mobile: this.mobile,
        },
      };
      this.cancel();
      this.postApi(params).then((response) => { });
    },
    cancel() {
      this.stopSend = true;
      this.addEvent({ name: "stopSendConfirmEmailMobile" });
      this.removeLoading(["cancel"]);
    },
  },
};
</script>
<style scoped>
.main {
  margin: 20px;
}

.div-btn {
  margin-top: 10px;
}
</style>